@import '../../../../scss/theme-bootstrap';

.email-signup-block {
  margin: 20px 0;
  &__inner {
    padding: 10% 0;
    @media #{$cr19-large-up} {
      padding: 5% 0 4% 0;
    }
  }
  &__header,
  &__container {
    width: 100%;
  }
  &__header {
    font-size: 20px;
    line-height: get-line-height(20px, 24px);
    padding-#{$rdirection}: 0;
    @media #{$cr19-large-up} {
      font-size: 24px;
      line-height: get-line-height(24px, 28px);
      padding-#{$rdirection}: 10px;
    }
  }
  &__headline {
    .body-text {
      font-size: 0.9375em;
      p {
        letter-spacing: .02em;
        line-height: 1.33333;
      }
    }
  }
  &__body {
    @media #{$cr19-large-up} {
      display: flex;
      flex-direction: row;
    }
    .email-signup-block__sub-headline {
      @media #{$cr19-large-up} {
        width: 50%;
      }
      padding-bottom: 30px;
      @media #{$cr19-large-up} {
        padding-bottom: unset;
      }
    }
    .email-signup-block__container {
      padding-top: 1px;
      position: relative;
      @media #{$cr19-large-up} {
        width: 50%;
        padding-top: 0;
      }
    }
  }
  &__gdpr {
    margin: 0 0 20px;
    width: 100%;
    @media #{$cr19-large-up} {
      margin: 0 0 5px;
      float: #{$ldirection};
    }
    &:hover > .email-signup-block__gdpr-overlay {
      display: block;
      z-index: 99;
    }
    .email-signup-block__gdpr-label {
      cursor: pointer;
      text-align: #{$rdirection};
      text-decoration: underline;
      @media #{$cr19-large-up} {
        float: #{$rdirection};
        max-width: 400px;
        padding: 5px;
      }
    }
    .email-signup-block__gdpr-overlay {
      background-color: $color-white;
      border-radius: 5px;
      border: 1px solid $color-black;
      bottom: 25px;
      color: $cr19-text-black;
      display: none;
      padding: 15px;
      position: absolute;
      #{$rdirection}: 0;
      width: 300px;
      @media #{$cr19-large-up} {
        width: 400px;
      }
      @media #{$small-only} {
        width: 100%;
      }
      a {
        color: $cr19-text-black;
        display: inline;
        text-decoration: underline;
        &:hover {
          color: $cr19-text-black;
        }
      }
      p {
        margin: 0;
      }
    }
  }
  &__form-content {
    margin: 0 auto 5px;
    text-align: #{$ldirection};
    .email-signup-block__form-email,
    .email-signup-block__form-cta {
      input {
        @include text-body-text($font-family: $helvetica-bold-font-family);
        @include swap_direction(margin, 0 6px 7px 0);
      }
    }
    .email-signup-block__form-email {
      border-bottom: 1px solid $cr19-text-black;
      color: $cr19-text-black;
      display: inline-block;
      padding: 0;
      width: 70%;
      input {
        &::placeholder {
          color: $cr19-text-grey;
        }
      }
    }
    .email-signup-block__form-cta {
      border-bottom: 1px solid $cr19-text-black;
      display: inline-block;
      margin-#{$ldirection}: -5px;
      padding: 0;
      width: 30%;
      input {
        color: $cr19-text-grey;
        text-align: #{$rdirection};
        width: 100%;
      }
    }
    .email-signup-block__form-pc-email-promotions {
      float: #{$ldirection};
      margin: 10px 0;
      width: 100%;
    }
    input[type='text'],
    input[type='submit'] {
      background-color: transparent;
      border: 0;

      &::placeholder {
        color: $color-cl-dark-grey;
      }
    }

    input[type='submit'] {
      color: $color-cl-dark-grey;
    }

    // Custom radio and checkbox styling
    input[type='checkbox'] {
      position: absolute;
      #{$ldirection}: -9999px;
      ~ label,
      ~ .label {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
        .label-content {
          display: table;
        }
        .email-signup-block__svgicon-checkbox {
          .svgicon {
            width: 24px;
            height: 24px;
            vertical-align: middle;
            use {
              fill: $color-white;
              stroke: $color-black;
            }
          }
        }
      }
      &:checked {
        ~ label,
        ~ .label {
          .email-signup-block__svgicon-checkbox {
            .svgicon {
              use {
                fill: $color-black;
              }
            }
          }
        }
      }
    }
  }
  &__form {
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
  }
  &__form-messages {
    margin: 0 auto;
    text-align: #{$ldirection};
    width: 100%;
  }
  &__message--success {
    @media #{$cr19-large-up} {
      width: 100%;
    }
    .email-signup-block__success {
      display: inline-block;
      padding: 0;
      width: 100%;
    }
  }
  &__field {
    float: $ldirection;
    line-height: 26px;
    padding-bottom: 10px;
    width: 100%;
    @media #{$cr19-large-up} {
      padding-bottom: 12px;
    }
  }
  &__legal-copy {
    margin: 0 auto;
    max-width: 75%;
    overflow: hidden;
    padding-top: 10px;
    text-align: center;
    width: 100%;
  }
  &__success-header {
    @include text-body-text($font-family: $helvetica-bold-font-family);
    border-bottom: 1px solid $cr19-border-light;
    padding-bottom: 20px;
  }
  &__success-offer {
    padding-top: 10px;
  }
  &__success-cta {
    cursor: pointer;
    margin-top: 10px;
    text-decoration: underline;
  }
  &__success-terms,
  &__success-reminder {
    line-height: 1em;
    padding-top: 10px;
  }
  &__error {
    color: $cr19-error;
    font-size: 16px;
    padding-bottom: 0;
    padding-top: 10px;
    .email-signup-block__form-msg & {
      @media #{$cr19-large-up} {
        padding-bottom: 0;
      }
    }
  }
  &__svgicon-checkbox {
    margin-#{$rdirection}: 8px;
    margin-#{$ldirection}: -5px;
  }
  &__email-promotion-text {
    margin-top: 4px;
    @include text-body-text--fine;
    color: $cr19-text;

    p {
      margin: 0;
    }
    a {
      @include text-link--style-7;
      @include text-body-text--fine;
      color: $cr19-text;
    }
  }
}
